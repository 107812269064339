import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

// We can't reuse theme variables here because the ReactOnRailsToast component is used for Saas Admin, and is not part of the ThemeProvider
export const ToastNotificationWithStructuredContentContainer = styled(ToastContainer)`
  width: unset;
  min-width: 360px;
  max-width: 600px;
  .Toastify__toast {
    min-height: 80px;
    border-radius: 4px;
    border: 1px solid rgb(241, 241, 241);
    color: rgb(42, 42, 42);
    background: rgb(255, 255, 255);
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
    font-size: 14.224px;
    line-height: 18px;
  }
  .Toastify__close-button {
    opacity: 1;
  }
  .Toastify__close-button > svg {
    width: 20px;
    height: 20px;
    color: rgb(57, 57, 57);
  }
`;
